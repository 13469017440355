











































import { Component, Prop, Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

// components
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import TiptapEditor from '@/components/_uikit/editor/TiptapEditor.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import SupportModule from '@/store/modules/support'
import { FaqResource, FaqStoreRequest } from '@/store/types'

@Component({
  components: {
    Dialog,
    ModalWrapper,
    TextInput,
    TiptapEditor,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class CreateEditAnswerFAQModal extends NotifyMixin {
  @Prop({ default: null })
  private answer!: FaqResource | null

  @Prop({ default: false })
  private visible!: boolean

  private buttonList = ['bold', 'italic', 'underline', 'strike', 'color', 'marker', 'align', 'bulletList', 'blockquote', 'link', 'image', 'formatClear']

  private form: FaqStoreRequest = {
    answer: '',
    question: '',
  }

  private keyID = Date.now()

  private handleClose () {
    this.$emit('update:visible', false)
    this.$emit('closeModal')
  }

  private handleConfirm() {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          if (this.answer) {
            SupportModule.updateFAQ({ body: this.form, id: this.answer.id })
              .then(() => {
                this.notifySuccess('Вопрос обновлён')
                requestAnimationFrame(() => (form.reset()))
                this.handleClose()
              })
              .catch(this.notifyError)
          } else {
            SupportModule.createFAQ(this.form)
              .then(() => {
                this.notifySuccess('Вопрос создан')
                requestAnimationFrame(() => (form.reset()))
                this.handleClose()
              })
              .catch(this.notifyError)
          }
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  @Watch('visible')
  private watchVisible(value: boolean) {
    if (value && this.answer) {
      this.form = {
        answer: this.answer.answer,
        question: this.answer.question,
      }
    }
    if (!value) {
      this.keyID = Date.now()
      this.form = {
        answer: '',
        question: '',
      }
    }
  }
}
