




























































import { Component, Mixins, Ref } from 'vue-property-decorator'
import { Bind, Debounce } from 'lodash-decorators'

// components
import Confirmation from '@/components/modals/Confirmation.vue'
import CreateEditAnswerFAQModal from '@/components/modals/CreateEditAnswerFAQModal.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
// store
import SupportModule from '@/store/modules/support'
import { FaqResource, NameValueResource } from '@/store/types'

@Component({
  components: {
    Confirmation,
    CreateEditAnswerFAQModal,
    TextInput,
  },
})
export default class FAQList extends Mixins(NotifyMixin, PermissionsMixin) {
  @Ref() confirm!: Confirmation

  private get search() {
    return SupportModule.faqSearch
  }

  @Bind()
  @Debounce(500)
  private set search(value: string) {
    if (value !== this.search) {
      SupportModule.setFAQSearch(value)
      this.fetchFAQList(value)
    }
  }

  private get faqList() {
    return SupportModule.faqList
  }

  private get openedFAQList() {
    return SupportModule.openedFAQList
  }

  private isLoaded = false
  private faqListState: number[] = []
  private showCreateEditFAQModal = false
  private selectAnswer: FaqResource | null = null

  private mounted() {
    if (!this.faqList.length)
      this.fetchFAQList()
    else
      this.isLoaded = true
  }

  private handleNewAnswer() {
    this.showCreateEditFAQModal = true
  }

  private fetchFAQList(query?: string) {
    this.isLoaded = false
    SupportModule.fetchFAQList({ query })
      .then(() => {
        this.faqListState = []
        this.faqListState = [...this.faqListState]
      })
      .catch(this.notifyError)
      .finally(() => {
        this.isLoaded = true
      })
  }

  private handleDelete(item: NameValueResource) {
    this.confirm.open(
      'Удаление ответа',
      'Вы уверены, что хотите удалить ответ на вопрос?',
      {
        buttonConfirmText: 'Удалить',
      },
    )
      .then(() => {
        SupportModule.deleteFAQ(item.value as number)
          .then(() => {
            this.notifySuccess('Ответ успешно удален')
          })
          .catch(this.notifyError)
      })
      .catch(() => {return})
  }

  private loadAnswer(id: number, index: number) {
    if (this.faqListState.includes(index)) {
      this.faqListState = this.faqListState.filter(item => item !== index)
      return
    }

    if (this.openedFAQList[id]) {
      this.faqListState.push(index)
      return
    }

    SupportModule.fetchFAQ(id)
      .then(() => {
        this.faqListState.push(index)
      })
      .catch(this.notifyError)
  }

  private handleEdit(value: NameValueResource) {
    if (this.openedFAQList[value.value as number]) {
      this.selectAnswer = this.openedFAQList[value.value as number]
      this.showCreateEditFAQModal = true
    } else {
      SupportModule.fetchFAQ(value.value as number)
        .then(response => {
          this.selectAnswer = response
          this.showCreateEditFAQModal = true
        })
        .catch(this.notifyError)
    }
  }
}
